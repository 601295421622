




















import Recommend from '@/components/Recommend.vue';
import { useMainStore } from '@/store';
import { useApi } from '@/util/useApi';
import { computed, defineComponent, onMounted, toRefs } from '@vue/composition-api';
import { getNewsDetail } from './api';

export default defineComponent({
  components: { Recommend },
  setup(_, { root }) {
    const { state } = useMainStore();
    const { result: detail, refresh } = useApi((id: number) => getNewsDetail(id), null, false);

    onMounted(() => {
      if (typeof root.$route.params.id !== 'undefined') {
        refresh(Number(root.$route.params.id));
      }
    });

    const content = computed(() =>
      detail.value?.content.replaceAll('static.zhucc.cc', 'ststatic.yjhmittc.com'),
    );

    return {
      content,
      detail,
      refresh,
      ...toRefs(state),
    };
  },
});
